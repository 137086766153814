import React from 'react'
import PropTypes from 'prop-types'

import Container from '@objects/container'
import Stage from '@components/stage'
import useStaticModule from '@hooks/useStaticModule'
import Copy from '@objects/copy'

function Impressum({ pageContext }) {
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const copy = getStaticFieldValue('impressum.copy', 'impressum.copy.text')

  return (
    <>
      <Stage alignTop={true} type={'generic'} title={pageContext.page.title}
      ariaLabel={pageContext.page.title}
      />
      <Container width="sm" ariaLabel="arialabel.impressum">
        <Copy type="article" html={copy} component="div" />
      </Container>
    </>
  )
}

Impressum.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default Impressum
